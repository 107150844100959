import React, { useState, useRef, useEffect } from 'react';
import { Link } from "gatsby"

import useOutsideClick from "../../utils/useOutsideClick";
import styles from './styles.module.scss';
import Hamburger from "./components/Hamburger";


const MainNavigation = () => {
    const ref = useRef();

    const items = [
        {
            label: 'Home',
            to: '/'
        }
    ];

    const [activeParent, setActiveParent] = useState(null);
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

    const hamburgerClickHandler = () => {
        setMobileMenuOpened(!mobileMenuOpened);
    };

    const parentClickHandler = ({ target }) => {
        const id = target.getAttribute('data-id');

        setActiveParent(activeParent === id ? null : id);
    };

    const keydownHandler = ({ keyCode }) => {
        if (keyCode !== 27) {
            return;
        }

        setActiveParent(null);
        setMobileMenuOpened(null);
    };

    useOutsideClick(ref, () => {
        setActiveParent(null);
    });

    useEffect(() => {
        if (activeParent || mobileMenuOpened) {
            document.addEventListener('keydown', keydownHandler);

            return;
        }

        document.removeEventListener('keydown', keydownHandler);
    }, [activeParent, mobileMenuOpened]);

    return (
        <nav aria-label="Hoofdmenu" className={styles.mainNavigation}>
            <Hamburger expanded={mobileMenuOpened} clickHandler={hamburgerClickHandler}/>

            <ul className={styles.mainNavigationList} ref={ref}>
                {items.map((item, index) => (
                    <li className={styles.mainNavigationItem} key={`main-navigation-${index.toString()}`}>
                        {item.to ? (
                            <Link className={styles.mainNavigationAnchor} to={item.to}>{item.label}</Link>
                        ) : (
                            <>
                                <button className={styles.mainNavigationButton} data-id={item.id} aria-expanded={activeParent === item.id} onClick={parentClickHandler}>
                                    {item.label}
                                    ▼
                                </button>
                                <ul className={styles.mainNavigationSublist}>
                                    {item.items.map((item, index) => (
                                        <li key={`main-navigation-${index.toString()}`}>
                                            <Link to={item.to}>{item.label}</Link>
                                            <p>{item.description}</p>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default MainNavigation;
