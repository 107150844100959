import React from 'react';
import PropTypes from 'prop-types';

import Header from '../../components/Header';
import Footer from "../../components/Footer";
import styles from './styles.module.scss';

const GeneralLayout = ({ children }) => {
    return (
        <div className={styles.layout}>
            <Header/>
            <main className={styles.main}>
                {children}
            </main>
            <Footer/>
        </div>
    )
};

GeneralLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default GeneralLayout;
