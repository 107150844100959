import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Heading = ({ className, level, type, children }) => {
    const safeHeading = level ? level.toLowerCase() : '';
    const Title = safeHeading ? safeHeading : 'p';

    return (
        <Title className={`${className} ${styles[type]}`}>{children}</Title>
    );
};

Heading.defaultProps = {
    level: 'h1',
    type: 'default',
    className: '',
};

Heading.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf(['default','section', 'hero', 'footer']),
    level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

export default Heading;
