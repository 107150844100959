import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Hamburger = ({expanded, clickHandler}) => (
    <button className={styles.hamburger} aria-expanded={expanded} onClick={clickHandler}>
        <span className={styles.hamburgerSlices}>
            <span/>
            <span/>
            <span/>
        </span>
        <span className={styles.hamburgerLabel}>Menu</span>
    </button>
);

Hamburger.propTypes = {
    expanded: PropTypes.bool.isRequired,
    clickHandler: PropTypes.func.isRequired,
};

export default Hamburger;
