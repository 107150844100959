import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Container = ({children, large}) => (
    <div className={large ? styles.containerLarge : styles.container}>
        {children}
    </div>
);

Container.defaultProps = {
    large: false,
};

Container.propTypes = {
    children: PropTypes.node.isRequired,
    large: PropTypes.bool,
};

export default Container;
