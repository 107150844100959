import React from 'react';

import { Link } from 'gatsby';

import Container from "../Container";
import Heading from "../Heading";
import styles from './styles.module.scss';

const Footer = () => {
    const columns = [
        {
            items: [
                {
                    label: 'Warzone Class Generator',
                    to: '/'
                },
            ]
        }
    ];

    return (
        <footer className={styles.footer}>
            <Container large>
                Copyright 2020

                <div className={styles.container}>
                    {columns.map((column, index) => (
                        <div key={`footer-column-${index.toString()}`}>
                            {column.heading && (
                                <Heading type="footer" level="h2">{column.heading}</Heading>
                            )}

                            <ul>
                                {column.items.map((item, index) => (
                                    <li key={`footer-item-${index.toString()}`}>
                                        <Link className={styles.link} to={item.to}>{item.label}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
