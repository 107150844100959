import React from 'react';
import PropTypes from 'prop-types';
import Container from "../Container";
import MainNavigation from "../MainNavigation";
import styles from './styles.module.scss';

const Header = ({ title }) => (
    <header className={styles.header}>
        <Container large>
            <div className={styles.wrapper}>
                <span className={styles.title}>{title}</span>

                <MainNavigation/>
            </div>
        </Container>
    </header>
);

Header.defaultProps = {
    title: 'MW Random Class Generator',
};

Header.propTypes = {
    title: PropTypes.string,
};

export default Header;
